crdtrx-pile {
  pointer-events: none;
  position: absolute;
}

.PileFootprint {
  width: 100%;
  height: 100%;
  border: 1px solid black;
}
