.Surface {
}

.Surface-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 300px;
  height: 100%;
  min-height: 300px;
}

.Surface-tableLabel {
  display: block;
  transform: rotate(-37deg);
  opacity: 0.8;
  font-size: 200%;
  text-align: center;
}
