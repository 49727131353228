:root {
  --table-fg-color: white;
  --table-bg-color: #25671a;
}

crdtrx-table {
  position: relative;
  z-index: 1;
  border: 2px solid var(--table-fg-color);
  border-radius: 5px;
  background-color: var(--table-bg-color);
  width: 95vw;
  min-width: 300px;
  height: 95vh;
  min-height: 300px;
  color: var(--table-fg-color);
}

crdtrx-table > [slot=surface] {
  width: 100%;
  height: 100%;
}

/* All items on the surface of the table */
crdtrx-table > [slot=surface] > * {
  position: absolute;
}
