body {
  background-color: var(--color-dark-dark);
  color: var(--color-light);
}

a {
  color: currentColor;
}

button {
  border: 1px solid currentColor;
  border-radius: 5px;
  background-color: var(--color-light);
  padding: 5px;
}
