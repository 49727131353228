/* Reset the default styles */
crdtrx-card {
  display: block;
  position: absolute;
  transition: transform 0.3s ease;
  border: none;
  background-color: transparent;
}

/* Card is being animated by the impetus lib */
crdtrx-card.hasImpetus {
  transition-property: none;
}

/* Pre-style, give layout, replicate crdtrx-card eventual styles, etc. */
crdtrx-card:not(:defined) {
  display: block;
  transform: translate3d(-71px, -96px, 0);
  width: 71px;
  height: 96px;
}

crdtrx-card > [slot=adjacent] {
  display: none;
  position: absolute;
  z-index: 2;
  width: 50px;
}

crdtrx-card:hover > [slot=adjacent] {
  display: block;
}
